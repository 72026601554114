<template>
  <router-view />
</template>
<script lang="ts" setup>


</script>
<style lang="scss">
html,body,#app{
    height:100%;
    width:100%;
    font-family: Arial, Helvetica, sans-serif;
  }
</style>
