export default {
    main:{
        'title':'图片标记助手',
        'subTitle':'PhotosMeasure',
        'description':'设计装修，量房量尺，照片标注好帮手！',
        'feature_1_title':'主要功能',
        'feature_1_mark':'图片标记助手 PhotosMeasure 是一款简洁方便的图片标注工具，能够让您方便迅速的在图片上标记出物体的大小等信息，大大缩短您在装修量尺过程中纪录时间和繁琐度，提高您的工作效率！',
        'feature_2_title':'项目管理',
        'feature_2_mark1':'支持多个项目编辑和保存',
        'feature_2_mark2':'项目按照文件夹管理和分类',
        'feature_2_mark3':'支持导出项目方便多设备共享',
        'feature_3_title':'高亮文字和绘图',
        'feature_3_mark1':'标记清晰、一目了然',
        'feature_3_mark2':'有效的展示和沟通',
        'feature_3_mark3':'随时保存随时编辑',
        'feature_4_title':'测距仪蓝牙数据同步',
        'feature_4_mark1':'实时数据传输解放双手',
        'feature_4_mark2':'拍照划线标注一步到位',
        'feature_4_mark3':'适合装修工程产品设计等',
        'feature_5_title':'丰富的样式和功能',
        'feature_5_mark1':'手动拖拽标注，多种色彩线段和文字标注',
        'feature_5_mark2':'角度标记，多种不同线段端点形态',
        'feature_5_mark3':'文本注释标记，标注结果保存和导出',
        'business':'商务合作',
        'contact':"官网微信",
    },
}