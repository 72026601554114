import { createI18n } from 'vue-i18n';

// 导入语言包
import en from '@/locales/en';
import zh from '@/locales/zh';

const i18n = createI18n({
    legacy: false,
    locale: getLocale()||'zh', // 默认语言
    globalInjection: true,
    messages: {
      en, // 英文语言包
      zh, // 中文语言包
    },
});

function getLocalStorage(){
  if(localStorage.getItem('user')){
    const user:any = JSON.parse(localStorage.getItem('user')!)
    if(user.PM_languageType){
      return user.PM_languageType
    }
  }
}
function getLocale() {
  const userCountry = getLocalStorage()
  const supportedLocales = ['en', 'zh']; // 支持的语言列表
  const browserLocale = navigator.language.split('-')[0]; // 获取浏览器语言
  // 检查浏览器语言是否被支持
  if(userCountry){
    return userCountry
  }else{
    if (supportedLocales.includes(browserLocale)) {
      return browserLocale;
    }
  }
}
export default i18n