export default {
    main:{
        'title': 'PhotosMeasure',
		'subTitle': 'Mark & Measure on Images',
		'description': 'Ideal for design and renovation projects, room measurements, and photo annotations!',
		'feature_1_title': 'Main Features',
		'feature_1_mark': 'PhotosMeasure is a simple and convenient image annotation tool that allows you to quickly mark the size of objects on photos. It greatly reduces the time and complexity of recording measurements during renovations, improving your work efficiency!',
		'feature_2_title': 'Project Management',
		'feature_2_mark1': 'Supports editing and saving multiple projects',
		'feature_2_mark2': 'Projects are managed and categorized in folders',
		'feature_2_mark3': 'Supports exporting projects for easy multi-device sharing',
		'feature_3_title': 'Highlight Text and Drawing',
		'feature_3_mark1': 'Clear and straightforward annotations',
		'feature_3_mark2': 'Effective presentation and communication',
		'feature_3_mark3': 'Save and edit at any time',
		'feature_4_title': 'Bluetooth Data Sync for Digital Devices',
		'feature_4_mark1': 'Real-time data transmission frees your hands',
		'feature_4_mark2': 'Photo marking and drawing in one step',
		'feature_4_mark3': 'Suitable for renovation, engineering, product design, etc.',
		'feature_5_title': 'Rich Styles and Widgets',
		'feature_5_mark1': 'Manual drag annotations, various colored lines, and text annotations',
		'feature_5_mark2': 'Angle markings, various endpoint shapes for lines',
		'feature_5_mark3': 'Text annotation marks, saving and exporting annotation results',
		'business': 'Contact us via Email',
		'contact': 'Official social media',

    },
}